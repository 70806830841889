import { Button, ButtonProps } from "@chakra-ui/react";

export const CustomButton = ({
  label,
  variant = "solid",
  handleClick,
  ...props
}: {
  label: string;
  variant?: "solid" | "outline" | "ghost" | "link" | "unstyled";
  handleClick: () => void;
} & ButtonProps) => {
  return (
    <Button
      w="60%"
      m={10}
      borderRadius="8px"
      bg="purple.800"
      color="white"
      variant={variant}
      onClick={handleClick}
      {...props}
    >
      {label}
    </Button>
  );
};
