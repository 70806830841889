import { mode } from "@chakra-ui/theme-tools";

export const theme = {
  colors: {
    gray: {
      50: "#FAFBFC",
      100: "#EEEEEE",
      200: "#E8EBF1",
      300: "#DDE1EC",
      400: "#BCC2D4",
      500: "#98A3B8",
      600: "#727C8D",
      700: "#484D62",
      800: "#2C303E",
      900: "#010535",
    },
    red: {
      50: "#FFEAF0",
      300: "#D93566",
      500: "#C02655",
      600: "#B11F4B",
    },
    green: {
      50: "#E9F9F2",
      500: "#089057",
      600: "#31C183",
    },
    purple: {
      50: "#EAEAFF",
      100: "#4E4AF2",
      300: "#E7EBFD",
      400: "#D8DEF9",
      500: "#7D88BD",
      600: "#4F527A",
      700: "#3D427F",
      800: "#313566",
      900: "#22254A",
    },
    terracotta: {
      100: "#FEE8E5",
      500: "#E3796A",
    },
    lavender: {
      100: "#F1F3FD",
    },
    transparentGray: {
      100: "#01053542",
    },
    white: {
      50: "#F5F6F9",
      100: "#F8F9FB",
    },
  },
  components: {
    Button: {
      baseStyle: {
        padding: "0.5rem",
        fontWeight: "bold",
        textTransform: "uppercase",
        borderRadius: "base",
      },

      variants: {
        solid: (props: Record<string, string>) => {
          if (props.colorScheme === "blue") {
            const bg = mode(`blue.500`, `blue.500`)(props);

            return {
              bg,
              color: "white",
              _hover: {
                bg: mode(`blue.600`, `blue.600`)(props),
                _disabled: {
                  bg,
                },
              },
              _active: { bg: mode(`blue.700`, `blue.700`)(props) },
            };
          }
          return {};
        },
      },
      defaultProps: {
        size: "md",
        variant: "outline",
      },
    },
  },
  styles: {
    global: {
      "*": {
        padding: 0,
        margin: 0,
      },
      button: {
        backgroundColor: "gray.200",
      },
      _focus: { outline: " 0 !important" },
    },
  },
};
