import axios, { AxiosError } from "axios";
import { useMutation, UseMutationOptions } from "react-query";
import { RequestBody, FormResponse } from "./types";

export const useSubmitForm = (
  options?: UseMutationOptions<FormResponse, AxiosError, RequestBody>
) => {
  return useMutation(submitForm, options);
};

const submitForm = async (request: RequestBody): Promise<FormResponse> => {
  const { data: res } = await axios.post(
    "https://api-in.dev.bureau.id/v1/demo/user_fingerprint_api",
    request
  );
  return res;
};
