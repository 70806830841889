import { CardHeader, VStack } from "@chakra-ui/react";
import { ReactNode } from "react";

export const CustomCardHeader = ({ children }: { children: ReactNode }) => {
  return (
    <CardHeader as={VStack} p={20} borderRadius="12px 12px 0 0" bg="purple.50">
      {children}
    </CardHeader>
  );
};
