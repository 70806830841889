import { FormLabel, Input, Stack } from "@chakra-ui/react";
import { ChangeEvent } from "react";

export const CustomInput = ({
  label,
  type,
  placeholder,
  value,
  handleChange,
}: {
  label: string;
  type: string;
  placeholder: string;
  value: string;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
}) => {
  return (
    <Stack minW="60%" marginTop={20}>
      <FormLabel fontSize="18">{label}:</FormLabel>
      <Input
        type={type}
        placeholder={placeholder}
        value={value}
        p={6}
        borderBottom="1px solid #DDE1EC"
        _placeholder={{ color: "gray.500" }}
        onChange={handleChange}
      />
    </Stack>
  );
};
