import { Box, SimpleGrid, VStack, Text } from "@chakra-ui/react";

export const BehaviourScore = ({
  behaviorScore,
  overallScore,
  deviceMatch,
}: {
  behaviorScore: number;
  overallScore: number;
  deviceMatch: number;
}) => {
  return (
    <SimpleGrid
      as={Box}
      p={30}
      color="gray"
      fontWeight="semibold"
      columns={2}
      width="100%"
    >
      <VStack alignItems="start">
        <Text fontSize="20">Behaviour Score: {behaviorScore}</Text>
        <Text fontSize="20">Overall Score: {overallScore}</Text>
      </VStack>
      <VStack>
        <Text fontSize="20">Device Match: {deviceMatch}</Text>
      </VStack>
    </SimpleGrid>
  );
};
