import { Card, Box, Image } from "@chakra-ui/react";
import { CustomCardHeader } from "./CustomCardHeader";
import { UserFormBody } from "./UserFormBody";

export const UserForm = () => {
  return (
    <Card
      as={Box}
      borderRadius="12px"
      boxShadow="0 0 2px gray"
      minW="30%"
      maxW="50%"
      paddingBottom={20}
    >
      <CustomCardHeader>
        <Image src="Logo.svg" alt="Bureau Logo" />
      </CustomCardHeader>
      <UserFormBody />
    </Card>
  );
};
