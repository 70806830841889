import {
  Box,
  CardBody,
  FormControl,
  Stack,
  useToast,
  VStack,
} from "@chakra-ui/react";
import Moonsense from "@moonsense/moonsense-web-sdk";
import {
  createUserWithEmailAndPassword,
  getAuth,
  signInWithEmailAndPassword,
} from "firebase/auth";
import React from "react";
import { useEffect, useState } from "react";
import { BehaviourScore } from "./BehaviourScore";
import { CustomButton } from "./CustomButton";
import { CustomInput } from "./CustomInput";
import { FormResponse } from "./types";
import { useSubmitForm } from "./useSubmitForm";

export const UserFormBody = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [hasSessionStarted, setHasSessionStarted] = useState(false);
  const [sessionID, setSessionID] = useState("");
  const [signin, setSignin] = useState("");
  const { mutate, data } = useSubmitForm();
  const {
    training_sessions_left: sessionsLeft,
    behavior_score: behaviorScore,
    overall_score: overallScore,
    device_match: deviceMatch,
  } = data || ({} as FormResponse);

  const startSession = () => {
    return Moonsense.startSession({ duration: 1800000 });
  };

  useEffect(() => {
    if (!hasSessionStarted) {
      setHasSessionStarted(true);
      startSession();
      const getSessionID = async () => {
        const id = await startSession().getRemoteId();
        setSessionID(id);
      };
      getSessionID();
    }
  }, [hasSessionStarted, sessionID]);

  const toast = useToast();

  const resetCredentials = () => {
    setEmail("");
    setPassword("");
    setHasSessionStarted(false);
    setSessionID("");
    Moonsense.stopAllSessions();
  };

  const addRecord = async () => {
    mutate({
      session_id: sessionID,
      username: email,
      device_fingerprint: `${Math.random() * 100000}`,
    });
    resetCredentials();
  };
  const handleSigninClick = () => {
    signInWithEmailAndPassword(auth, email, password)
      .then((res) => {
        if (signin) {
          addRecord();
        }
        setSignin(res.operationType);
      })
      .catch((error: Error) => {
        toast({
          duration: 9000,
          isClosable: true,
          render: () => (
            <Box
              bgColor="red"
              color="white"
              p={10}
              borderRadius={6}
              textAlign="center"
              fontSize="xl"
            >
              {error?.message}
            </Box>
          ),
        });
      });
    resetCredentials();
  };

  const handleSignupClick = () => {
    createUserWithEmailAndPassword(auth, email, password)
      .then((res) => setSignin(res.operationType))
      .catch((error: Error) => {
        toast({
          duration: 9000,
          isClosable: true,
          render: () => (
            <Box
              bgColor="red"
              color="white"
              p={10}
              borderRadius={6}
              textAlign="center"
              fontSize="xl"
            >
              {error?.message}
            </Box>
          ),
        });
      });
    resetCredentials();
  };

  const auth = getAuth();
  return (
    <CardBody as={Stack}>
      <FormControl as={VStack} id="email">
        <CustomInput
          label="Email address"
          placeholder="johndoe@gmail.com"
          value={email}
          type="email"
          handleChange={(e) => setEmail(e.target.value)}
        />
        <CustomInput
          label="Password"
          placeholder="******"
          value={password}
          type="password"
          handleChange={(e) => setPassword(e.target.value)}
        />
      </FormControl>
      <Box as={VStack} py={10} w="100%">
        {signin || sessionsLeft > 0 ? (
          <CustomButton
            label={signin && sessionsLeft === 0 ? "Test" : "Train"}
            color="white"
            handleClick={() => {
              handleSigninClick();
            }}
          />
        ) : (
          <>
            <CustomButton label="Sign In" handleClick={handleSigninClick} />
            <CustomButton
              label="Create Account"
              handleClick={handleSignupClick}
            />
          </>
        )}
        {sessionsLeft === 0 && behaviorScore ? (
          <BehaviourScore
            behaviorScore={behaviorScore}
            overallScore={overallScore}
            deviceMatch={deviceMatch}
          />
        ) : (
          <></>
        )}
      </Box>
    </CardBody>
  );
};
