import { ChakraProvider, Flex } from "@chakra-ui/react";
import React from "react";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { QueryClient, QueryClientProvider } from "react-query";
import { Moonsense } from "@moonsense/moonsense-web-sdk";
import { theme } from "./GlobalStyle";
import { UserForm } from "./UserForm";

const configureMoonsense = () => {
  const sdkConfig = {
    publicToken:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJDRXR5dURBdW03ZlZyNnNnZ0twZWdIIiwidHlwZSI6MX0.W85MXfpx6JrT68pyfBL7gVUhSxBIr689DAq7LzE5QoY",
  };
  return Moonsense.initialize(sdkConfig);
};

function App() {
  const queryClient = new QueryClient();
  const firebaseConfig = {
    apiKey: "AIzaSyCg0TbWXXiwpgssifW-sjprl3gr3bEFfrA",
    authDomain: "ufp-authentication.firebaseapp.com",
    databaseURL: "https://ufp-authentication-default-rtdb.firebaseio.com",
    projectId: "ufp-authentication",
    storageBucket: "ufp-authentication.appspot.com",
    messagingSenderId: "647961359463",
    appId: "1:647961359463:web:b9ae4f22b28aa5178490c2",
    measurementId: "G-JEM4VQ24KX",
  };

  const app = initializeApp(firebaseConfig);
  getAuth(app);
  configureMoonsense();

  return (
    <ChakraProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <Flex h={500} alignItems="center" justifyContent="center">
          <UserForm />
        </Flex>
      </QueryClientProvider>
    </ChakraProvider>
  );
}

export const moonsenseSdk: Moonsense = configureMoonsense();
export default App;
